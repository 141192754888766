export const RESERVATION_DEFAULT_CURRENCY = "MMK";
export const PRODUCT_DEFAULT_CURRENCY = "MMK";
export const UTM_CAMPAIGN = "utm_campaign";

export const SOURCE_TYPE_CAMPAIGN = 1;
export const SOURCE_TYPE_REFERRAL = 2;
export const SOURCE_TYPE_PROMO = 3;
export const SOURCE_TYPE_DEVICE = 4;
export const SOURCE_TYPE_CASHBACK = 5;
export const SOURCE_TYPE_AUTODISCOUNT = 6;

export const TASK_STATUS_OPEN = 16;
export const TASK_STATUS_CLOSED = 17;
export const TASK_STATUS_PROSPECT = 36;

export const ARRANGER_TAB_ID = 0;
export const PRODUCT_TAB_ID = 1;
export const TRANSACTION_TAB_ID = 2;
export const SOURCE_TAB_ID = 3;
export const OVERVIEW_TAB_ID = 4;
export const TASK_TAB_ID = 5;
export const EMPTY_TAB_ID = 6;

export const DRAFT_PRODUCT_STATUS_ID = 5;
export const HOLD_PRODUCT_STATUS_ID = 6;
export const ISSUED_PRODUCT_STATUS_ID = 7;
export const CANCELLED_PRODUCT_STATUS_ID = 8;
export const VOID_PRODUCT_STATUS_ID = 20;
export const UNCONFIRMED_PRODUCT_STATUS_ID = 22;
export const CONFIRMED_PRODUCT_STATUS_ID = 23;
export const ISSUED_PENDING_STATUS_ID = 37;
export const ISSUANCE_PENDING_STATUS_ID = 29;
export const SUBMIT_PRODUCT_STATUS_ID = process.env.REACT_APP_PRODUCT_SUBMIT_ID;

export const DRAFT_TRANSACTION_STATUS_ID = 13;
export const COMPLETED_TRANSACTION_STATUS_ID = 14;
export const CANCELLED_TRANSACTION_STATUS_ID = 15;
export const RECEIVED_TRANSACTION_STATUS_ID = 18;
export const REFUND_TRANSACTION_STATUS_ID = 19;
export const VOID_TRANSACTION_STATUS_ID = 21;
export const VOID_OLD_TRANSACTION_STATUS_ID = 20;
export const PENDING_CONFIRMATION_STATUS_ID = 34;
export const PAYMENT_DUE_STATUS_ID = 27;
export const SUBMIT_TRANSACTION_STATUS_ID =
  process.env.REACT_APP_TRANSACTION_SUBMIT_ID;

export const UNLINK_WARNING_TEXT =
  "Are you sure you want to UNLINK this? This operation cannot be undone.";
export const DELETE_WARNING_TEXT =
  "Are you sure you want to DELETE this? This operation cannot be undone.";
export const VOID_WARNING_TEXT =
  "Are you sure you want to VOID this? This operation cannot be undone.";

export const TRANSACTION_TYPE_PAYMENT = 1;
export const TRANSACTION_TYPE_DEPOSIT = 2;
export const TRANSACTION_TYPE_FINANCE = 3;
export const TRANSACTION_TYPE_REFUND = 4;

export const DOMESTIC_PRODUCT_TYPE_ID = 1;
export const DOMESTIC_ROUNDTRIP_PRODUCT_TYPE_ID = 12;
export const INTERNATIONAL_PRODUCT_TYPE_ID = 2;
export const HOTEL_REFERREAL_PRODUCT_TYPE_ID = 4;
export const HOTEL_PRODUCT_TYPE_ID = 11;
export const BUS_PRODUCT_TYPE_ID = 3;
export const CAR_PRODUCT_TYPE_ID = 6;
export const INTERNATIONAL_ROUNDTRIP_PRODUCT_TYPE_ID = 15;
export const BALLOON_PRODUCT_TYPE_ID = 5;
export const FLIGHT_ADDON_PRODUCT_TYPE_ID = 13;
export const HOTEL_ADDON_PRODUCT_TYPE_ID = 14;
export const BOOKING_FEE_PRODUCT_TYPE_ID = 16;

export const B2B_OFFLINE_PSA = 2;
export const B2C_OFFLINE_TELEPHONE = 11;

export const SALE_CATEGORY = [
  { value: 1, label: "Sale" },
  { value: 2, label: "Refund" },
  { value: 3, label: "Date/Time Change" },
  { value: 4, label: "Name Change" },
  { value: 5, label: "Sector Change" },
  { value: 7, label: "Refund - Cancellation Fee" },
  { value: 8, label: "Company Expense" },
  { value: 9, label: "Flight Time Change" },
  { value: 11, label: "Food & Beverage"},
];

export const DOMESTIC_META_DATA = [
  { key: "Flight Code", value: null, roundtrip: false },
  { key: "Fare Class", value: null, roundtrip: false },
  { key: "Departure Town", value: null, roundtrip: false },
  { key: "Arrival Town", value: null, roundtrip: false },
  { key: "Via Town", value: null, roundtrip: false },
  { key: "Departure Time", value: null, roundtrip: false },
  { key: "Arrival Time", value: null, roundtrip: false },
  { key: "Travel Date", value: null, roundtrip: false },
  { key: "Baggage Allowance", value: null, roundtrip: false },
  { key: "Valid Until", value: null, roundtrip: false },
  { key: "Referrer", value: '["offline"]', roundtrip: false },
];

export const DOMESTIC_ROUNDTRIP_META_DATA = [
  { key: "Flight Code", value: null, roundtrip: false },
  { key: "Fare Class", value: null, roundtrip: false },
  { key: "Departure Town", value: null, roundtrip: false },
  { key: "Arrival Town", value: null, roundtrip: false },
  { key: "Via Town", value: null, roundtrip: false },
  { key: "Departure Time", value: null, roundtrip: false },
  { key: "Arrival Time", value: null, roundtrip: false },
  { key: "Travel Date", value: null, roundtrip: false },
  { key: "Valid Until", value: null, roundtrip: false },
  { key: "Flight Code", value: null, roundtrip: true },
  { key: "Fare Class", value: null, roundtrip: true },
  { key: "Departure Town", value: null, roundtrip: true },
  { key: "Arrival Town", value: null, roundtrip: true },
  { key: "Via Town", value: null, roundtrip: true },
  { key: "Departure Time", value: null, roundtrip: true },
  { key: "Arrival Time", value: null, roundtrip: true },
  { key: "Travel Date", value: null, roundtrip: true },
  { key: "Baggage Allowance", value: null, roundtrip: false },
  { key: "Valid Until", value: null, roundtrip: true },
  { key: "Referrer", value: '["offline"]', roundtrip: false },
];

export const INTERNATIONAL_META_DATA = [
  { key: "Flight Code", value: null, roundtrip: false },
  { key: "Fare Class", value: null, roundtrip: false },
  { key: "Departure Town", value: null, roundtrip: false },
  { key: "Arrival Town", value: null, roundtrip: false },
  { key: "Via Town", value: null, roundtrip: false },
  { key: "Departure Time", value: null, roundtrip: false },
  { key: "Arrival Time", value: null, roundtrip: false },
  { key: "Travel Date", value: null, roundtrip: false },
  { key: "Baggage Allowance", value: null, roundtrip: false },
  { key: "Valid Until", value: null, roundtrip: false },
  { key: "Referrer", value: '["offline"]', roundtrip: false },
];

export const INTERNATIONAL_ROUNDTRIP_META_DATA = [
  { key: "Flight Code", value: null, roundtrip: false },
  { key: "Fare Class", value: null, roundtrip: false },
  { key: "Departure Town", value: null, roundtrip: false },
  { key: "Arrival Town", value: null, roundtrip: false },
  { key: "Via Town", value: null, roundtrip: false },
  { key: "Departure Time", value: null, roundtrip: false },
  { key: "Arrival Time", value: null, roundtrip: false },
  { key: "Travel Date", value: null, roundtrip: false },
  { key: "Valid Until", value: null, roundtrip: false },
  { key: "Flight Code", value: null, roundtrip: true },
  { key: "Fare Class", value: null, roundtrip: true },
  { key: "Departure Town", value: null, roundtrip: true },
  { key: "Arrival Town", value: null, roundtrip: true },
  { key: "Via Town", value: null, roundtrip: true },
  { key: "Departure Time", value: null, roundtrip: true },
  { key: "Arrival Time", value: null, roundtrip: true },
  { key: "Travel Date", value: null, roundtrip: true },
  { key: "Baggage Allowance", value: null, roundtrip: false },
  { key: "Valid Until", value: null, roundtrip: true },
  { key: "Referrer", value: '["offline"]', roundtrip: false },
];

export const HOTEL_META_DATA = [
  { key: "Room Name", value: null, roundtrip: false },
  { key: "Booker Email", value: null, roundtrip: false },
  { key: "Booker Phone", value: null, roundtrip: false },
  { key: "Check-in Date", value: null, roundtrip: false },
  { key: "Check-out Date", value: null, roundtrip: false },
  { key: "Room Quantity", value: null, roundtrip: false },
  { key: "Hotel Name", value: null, roundtrip: false },
  { key: "Hotel ID", value: null, roundtrip: false },
  { key: "Room Price Total", value: null, roundtrip: false },
  { key: "Hotel Address", value: null, roundtrip: false },
  { key: "Hotel Phone", value: null, roundtrip: false },
  { key: "Hotel Email", value: null, roundtrip: false },
  { key: "Flymya Commission", value: null, roundtrip: false },
  { key: "Hotel Price", value: null, roundtrip: false },
  { key: "Myanmar Citizen", value: null, roundtrip: false },
  { key: "Referral", value: null, roundtrip: false },
  { key: "Block ID", value: null, roundtrip: false },
  { key: "Block Quantity", value: null, roundtrip: false },
  { key: "Guest Quantity", value: null, roundtrip: false },
  { key: "Incremental Prices", value: null, roundtrip: false },
  { key: "Guest Names", value: null, roundtrip: false },
  { key: "Sparam", value: null, roundtrip: false },
  { key: "Currency", value: null, roundtrip: false },
  { key: "Mode", value: null, roundtrip: false },
  { key: "Referrer", value: '["offline"]', roundtrip: false },
];

export const BUS_META_DATA = [
  { key: "MMBus Operator", value: null, roundtrip: false },
  { key: "Bus Type", value: null, roundtrip: false },
  { key: "Departure Town", value: null, roundtrip: false },
  { key: "Arrival Town", value: null, roundtrip: false },
  { key: "Departure Station", value: null, roundtrip: false },
  { key: "Arrival Station", value: null, roundtrip: false },
  { key: "Departure Date", value: null, roundtrip: false },
  { key: "Arrival Date", value: null, roundtrip: false },
  { key: "Departure Time(Main Gate)", value: null, roundtrip: false },
  { key: "Arrival Time(Main Gate)", value: null, roundtrip: false },
  { key: "Departure Time", value: null, roundtrip: false },
  { key: "Arrival Time", value: null, roundtrip: false },
  { key: "Seat Number", value: null, roundtrip: false },
  { key: "No of seats", value: null, roundtrip: false },
  { key: "Referrer", value: '["offline"]', roundtrip: false },
];

export const CAR_META_DATA = [
  { key: "Pick-up Location", value: null, roundtrip: false },
  { key: "Drop-off Location", value: null, roundtrip: false },
  { key: "No. of Passenger", value: null, roundtrip: false },
  { key: "Travel Date", value: null, roundtrip: false },
  { key: "Pick-up Time", value: null, roundtrip: false },
  { key: "Trip Type", value: null, roundtrip: false },
  { key: "Referrer", value: '["offline"]', roundtrip: false },
];

export const BALLOON_META_DATA = [
  { key: "No. of Passenger", value: null, roundtrip: false },
  { key: "Date of Flight", value: null, roundtrip: false },
  { key: "Location", value: null, roundtrip: false },
  { key: "Myanmar Citizen", value: null, roundtrip: false },
  { key: "Starting From", value: null, roundtrip: false },
  { key: "Referrer", value: '["offline"]', roundtrip: false },
];

export const BALLOON_LOCATION_OPTIONS = [
  { value: "Bagan", label: "Bagan" },
  { value: "Inle", label: "Inle" },
  { value: "Loikaw", label: "Loikaw" },
  { value: "Mandalay", label: "Mandalay" },
  { value: "Ngapali", label: "Ngapali" },
];

export const MAR_NUMBER_TYPE = "NUMBER";
export const MAR_PERCENT_TYPE = "PERCENT";

export const CHECKED_PRODUCT_ID = 1;
export const UNCHECKED_PRODUCT_ID = 2;
export const CHECKED_TRANSACTION_ID = 3;
export const UNCHECKED_TRANSACTION_ID = 4;

export const CALCULATION_AMOUNT_TYPE = "MARGIN_AMOUNT";
export const CALCULATION_PERCENT_TYPE = "MARGIN_PERCENT";
export const CALCULATION_FULL_TYPE = "FULL";

export const DUPLICATE_MODE_CLONE = "CLONE";
export const DUPLICATE_MODE_MODIFY = "PAID_MODIFY";
export const DUPLICATE_MODE_REFUND = "REFUND";
export const DUPLICATE_MODE_REFUND_CANCELLATION = "REFUND_CANCELLATION";

export const ONLINE_PAYMENTS = [
  "KBZ Pay",
  "Wave Money",
  "Stripe",
  "MPU (2C2P)",
  "Paypal",
  "M-Pitesan",
  "123 OTC (2C2P)",
];

export const TRANSACTION_TYPES = [
  {
    value: 1,
    label: "Payment",
  },
  {
    value: 2,
    label: "Deposit",
  },
  {
    value: 4,
    label: "Refund",
  },
];
