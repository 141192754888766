import {
  SET_PRODUCT_CHECK_STATUS,
  SET_RESERVATION_PRODUCTS,
  SET_RESERVATION_TRANSACTIONS,
  SET_TRANSACTION_CHECK_STATUS,
} from "../../actions/types";

const initialState = {
  products: [],
  transactions: [],
  productChecked: [],
  transactionChecked: [],
};

const ReservationProducts = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESERVATION_PRODUCTS:
      state.products = action.payload;
      return { ...state };
    case SET_RESERVATION_TRANSACTIONS:
      state.transactions = action.payload;
      return { ...state };
    case SET_PRODUCT_CHECK_STATUS:
      state.productChecked = action.payload;
      return { ...state };
    case SET_TRANSACTION_CHECK_STATUS:
      state.transactionChecked = action.payload;
      console.log("transaction state is", state.transactionChecked);
      return { ...state };
    default:
      return state;
  }
};

export default ReservationProducts;
