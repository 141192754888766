import {
  SET_RESERVATION_ID,
  SET_RESERVATION_STATUS,
  SET_RESERVATION_FINANCIAL_STATUS,
  SET_RESERVATION_CHECKED_UNCHECKED,
  SET_RESERVATION_ASSIGNTO,
  SET_RESERVATION_ARRANGER,
  SET_RESERVATION_ARRANGER_SNAPSHOT,
  SET_RESERVATION_TOTAL,
  SET_RESERVATION_BALANCE,
  SET_RESERVATION_FINANCIAL_BALANCE,
  SET_RESERVATION_TAB,
  SET_RESERVATION_SUBTAB,
  SET_RESERVATION_CHILDSUBTAB,
  SET_RESERVATION_CURRENCY,
  SET_RESERVATION_EXCHANGE_RATE,
  SET_RESERVATION_DOCSTATUS,
  SET_RESERVATION_EMAILSTATUS,
  SET_EXCHANGE_RATE_ARRAY,
  SET_RESERVATION_SALESCHANNEL,
  SET_RESERVATION_LOGS,
  SET_RESERVATION_TRANSACTION_SAVE,
  SET_RESERVATION_PRODUCT_SAVE,
} from "./types";

const setReservationId = (data) => {
  return { type: SET_RESERVATION_ID, payload: data };
};

const setReservationStatus = (data) => {
  return { type: SET_RESERVATION_STATUS, payload: data };
};

const setReservationFinancialStatus = (data) => {
  return { type: SET_RESERVATION_FINANCIAL_STATUS, payload: data };
};

const setReservationCheckedUnchecked = (data) => {
  return { type: SET_RESERVATION_CHECKED_UNCHECKED, payload: data };
};

const setReservationAssignTo = (data) => {
  return { type: SET_RESERVATION_ASSIGNTO, payload: data };
};

const setReservationArranger = (data) => {
  return { type: SET_RESERVATION_ARRANGER, payload: data };
};

const setReservationArrangerSnapshot = (data) => {
  return { type: SET_RESERVATION_ARRANGER_SNAPSHOT, payload: data };
};

const setReservationTotal = (data) => {
  return { type: SET_RESERVATION_TOTAL, payload: data };
};

const setReservationBalance = (data) => {
  return { type: SET_RESERVATION_BALANCE, payload: data };
};

const setReservationFinancialBalance = (data) => {
  return { type: SET_RESERVATION_FINANCIAL_BALANCE, payload: data };
};

const setReservationTab = (data) => {
  return { type: SET_RESERVATION_TAB, payload: data };
};

const setReservationSubTab = (data, value) => {
  return {
    type: SET_RESERVATION_SUBTAB,
    payload: { tabIndex: data, index: value },
  };
};

const setReservationChildSubTab = (data) => {
  return { type: SET_RESERVATION_CHILDSUBTAB, payload: data };
};

const setReservationCurrency = (data) => {
  return { type: SET_RESERVATION_CURRENCY, payload: data };
};

const setReservationExchangeRate = (data) => {
  return { type: SET_RESERVATION_EXCHANGE_RATE, payload: data };
};

const setReservationDocStatus = (data) => {
  return { type: SET_RESERVATION_DOCSTATUS, payload: data };
};

const setReservationEmailStatus = (data) => {
  return { type: SET_RESERVATION_EMAILSTATUS, payload: data };
};

const setExchangeRateArray = (data) => {
  return { type: SET_EXCHANGE_RATE_ARRAY, payload: data };
};

const setReservationSalesChannel = (data) => {
  return { type: SET_RESERVATION_SALESCHANNEL, payload: data };
};

const setReservationLogs = (data) => {
  return { type: SET_RESERVATION_LOGS, payload: data };
};

const setreservationTransactionSave = (data) => {
  return { type: SET_RESERVATION_TRANSACTION_SAVE, payload: data };
};

const setreservationProductSave = (data) => {
  return { type: SET_RESERVATION_PRODUCT_SAVE, payload: data };
};

export {
  setReservationId,
  setReservationStatus,
  setReservationFinancialStatus,
  setReservationCheckedUnchecked,
  setReservationAssignTo,
  setReservationArranger,
  setReservationArrangerSnapshot,
  setReservationTotal,
  setReservationBalance,
  setReservationFinancialBalance,
  setReservationTab,
  setReservationSubTab,
  setReservationChildSubTab,
  setReservationCurrency,
  setReservationExchangeRate,
  setReservationDocStatus,
  setReservationEmailStatus,
  setExchangeRateArray,
  setReservationSalesChannel,
  setReservationLogs,
  setreservationTransactionSave,
  setreservationProductSave,
};
